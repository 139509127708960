<template>
  <div
    v-if="hasDivider(tournament.start_date)"
    class="divider w-full flexing text-32 text-white font-bold uppercase"
  >
    {{ $t('tournaments.tournamentDayEnd') }}
  </div>
  <div class="calendar-row w-full flex flex-row justify-between items-center space-x-3">
    <div
      class="left-side h-full flexing flex-grow cursor-pointer"
      :class="[
        `is-tournament-${tournament.tournament_state}`,
        `is-user-${tournament.user_state}`,
        index % 2 === 0 ? 'is-even' : 'is-odd',
        { 'is-locked relative': isLocked },
      ]"
      @click="
        $router.push({ name: $getWebView('TournamentsDetail'), params: { id: tournament.id } })
      "
    >
      <div
        v-if="isLocked"
        class="locked-overlay flex flex-row justify-start items-center absolute inset-0 z-1"
      >
        <div class="px-12 py-5">
          <app-icon icon-name="lock-lg" class="pointer-events-none" />
        </div>
      </div>
      <div class="p-5">
        <app-discipline-icon :discipline-id="tournament.discipline_id" :size="120" theme="light" />
      </div>
      <div class="info flex flex-col justify-center items-start p-5">
        <p class="text-32 text-texts-standard-default font-semibold uppercase truncate">
          {{ tournament.name }}
        </p>
        <p class="text-36 text-texts-standard-important font-bold uppercase truncate">
          {{ $translateDiscipline(tournament.discipline_id) }}
        </p>
        <p class="text-32 text-texts-standard-default font-normal">
          {{ $t('tournaments.attribute') }}: {{ tournament.attribute }}
        </p>
      </div>
      <div class="flex flex-col justify-center items-center space-y-4 ml-auto">
        <p class="text-32 text-texts-standard-default font-semibold uppercase">
          {{
            tournament.tournament_state === TournamentState.Finished
              ? $t('tournaments.yourRewards')
              : $t('tournaments.rewardForVictory')
          }}
        </p>
        <rewards
          reward-id="rewards"
          :reward-data="tournament.rewards"
          :icon-size="48"
          modifier-tag="tournaments"
        />
      </div>
      <div
        class="detail-button h-full flex flex-col justify-center items-center"
        :class="{
          'is-locked': isLocked,
        }"
        @click="onDetailButtonClick"
      >
        <div v-if="!isLocked" class="detail-button-icon" />
      </div>
    </div>
    <div
      class="right-side h-full flex flex-col justify-center items-center"
      :class="[
        `is-tournament-${tournament.tournament_state}`,
        `is-user-${tournament.user_state}`,
        { 'cursor-pointer': isJoined || isDrawing || isEvaluating },
      ]"
      @click="onTournamentButtonClick"
    >
      <tournament-button
        :tournament="tournament"
        :meta="meta"
        :context="context"
        @reload-data="$emit('reload-data')"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { getFormattedTimeFromHMS, getFormattedDateFromYMD } from '@/helpers'
import Rewards from '@/components/Rewards.vue'
import TournamentButton, { TournamentButtonContext } from './TournamentButton.vue'
import { useDisciplineStore } from '@/store/pinia/disciplinesStore'
import type { DisciplineMenuData } from '@/interfaces/DisciplineMenu'
import {
  TournamentState,
  TournamentUserState,
  type Tournament,
  type TournamentMeta,
} from '@/interfaces/Tournaments'
import { defineComponent, type PropType } from 'vue'
import { mapState } from 'pinia'

interface ComponentData {
  TournamentState: typeof TournamentState
}

export default defineComponent({
  components: {
    Rewards,
    TournamentButton,
  },
  props: {
    tournament: {
      type: Object as PropType<Tournament>,
      required: true,
    },
    meta: {
      type: Object as PropType<TournamentMeta>,
      required: true,
    },
    context: {
      type: String as PropType<TournamentButtonContext>,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  emits: ['reload-data'],
  data: (): ComponentData => ({
    TournamentState,
  }),
  computed: {
    ...mapState(useDisciplineStore, {
      disciplinesMenuData: 'getDisciplinesMenuData',
    }),
    isJoined(): boolean {
      return (
        this.tournament.tournament_state === TournamentState.Open &&
        this.tournament.user_state === TournamentUserState.Joined
      )
    },
    isDrawing(): boolean {
      return this.tournament.tournament_state === TournamentState.Drawing
    },
    isRunning(): boolean {
      return this.tournament.tournament_state === TournamentState.Running
    },
    isRunningAndCanPlay(): boolean {
      return this.isRunning && this.tournament.user_state === TournamentUserState.Running
    },
    isEvaluating(): boolean {
      return this.tournament.tournament_state === TournamentState.Evaluating
    },
    isFinished(): boolean {
      return this.tournament.tournament_state === TournamentState.Finished
    },
    isLocked(): boolean {
      return this.tournament.user_state === TournamentUserState.Locked
    },
    disciplineUnlockLevel(): number {
      return this.disciplinesMenuData?.find(
        (level: DisciplineMenuData): boolean => level.id === this.tournament.discipline_id,
      )?.levelToUnlock
    },
  },
  methods: {
    getFormattedTimeFromHMS,
    getFormattedDateFromYMD,
    onDetailButtonClick(): void {
      if (this.isLocked) return

      this.$router.push({
        name: this.$getWebView('TournamentsDetail'),
        params: { id: this.tournament.id },
      })
    },
    onTournamentButtonClick(): void {
      if (this.isJoined || this.isDrawing || this.isEvaluating) {
        this.onDetailButtonClick()
      }
    },
    hasDivider(date: string): boolean {
      const d = new Date(date)
      return d.getHours() === 0 && d.getMinutes() === 0
    },
  },
})
</script>

<style lang="scss" scoped>
.calendar-row {
  height: 10rem;

  .left-side {
    width: 88.625rem;
    border: solid 0.125rem #7477a4;

    &.is-even {
      @if $isSsm {
        background-color: rgba(82, 84, 120, 0.7);
      }
      @if $isWsm {
        background-color: #417491;
        border-color: #5883a3;
      }
    }

    &.is-odd {
      @if $isSsm {
        background-color: rgba(114, 114, 176, 0.7);
      }
      @if $isWsm {
        background-color: #236d9b;
        border-color: #6c9ec4;
      }
    }

    &.is-tournament-open.is-user-joined,
    &.is-tournament-drawing.is-user-joined {
      @include background(
        url('#{$path-images}/tournaments/calendar/joined-bg.avif'),
        contain,
        left
      );
      @if $isSsm {
        border-color: #0ee7cc;
      }
      @if $isWsm {
        border-color: #3fe443;
      }
    }

    &.is-tournament-running,
    &.is-tournament-evaluating {
      @include background(
        url('#{$path-images}/tournaments/calendar/running-bg.avif'),
        contain,
        left
      );
      @if $isSsm {
        border-color: #feb942;
      }
      @if $isWsm {
        border-color: #fadd1e;
      }
    }

    &.is-user-locked,
    &.is-locked {
      border: 0;
      .locked-overlay {
        background-color: rgba(47, 56, 87, 0.8);
        @if $isWsm {
          background-color: rgba($color: #152335, $alpha: 0.8);
        }
      }
    }

    .info {
      width: 29.375rem;
      padding-top: 1.5rem;
      padding-bottom: 1rem;
      line-height: 1.25;

      p {
        max-width: 100%;
      }
    }

    .rewards {
      min-width: 45.625rem;
      border-style: solid;
      border-width: 0.125rem;
      border-image-slice: 1;
      @if $isSsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba(144, 144, 193, 0.5),
          transparent
        );
        border-image-source: linear-gradient(
          to right,
          transparent,
          rgba(255, 255, 255, 0.5),
          transparent
        );
      }
      @if $isWsm {
        background-image: linear-gradient(
          to right,
          transparent,
          rgba($color: #6c9ec480, $alpha: 0.5),
          transparent
        );
        border-image-source: linear-gradient(
          to right,
          transparent,
          rgba($color: #ffffff80, $alpha: 0.5),
          transparent
        );
      }
    }

    .detail-button {
      width: 3.625rem;

      &:not(.is-locked) {
        @if $isSsm {
          background-color: #2f3857;
        }
        @if $isWsm {
          background-color: #0d2038;
        }
      }

      &-icon {
        width: 1.5rem;
        height: 2.813rem;
        @include background(url('#{$path-images}/tournaments/arrow-forward.avif'), contain);
      }
    }
  }

  .right-side {
    width: 26.125rem;
    @if $isSsm {
      background-color: rgba(47, 56, 87, 0.7);
      border: solid 0.125rem #7477a4;
    }
    @if $isWsm {
      background-color: #1b2f47;
      border: solid 0.125rem #6c9ec4;
    }

    &.is-tournament-open.is-user-joined,
    &.is-tournament-drawing.is-user-joined {
      @if $isSsm {
        border-color: #0ee7cc;
      }
      @if $isWsm {
        border-color: #3fe443;
      }
    }

    &.is-tournament-running,
    &.is-tournament-evaluating {
      @include background(url('#{$path-images}/tournaments/calendar/running-small-bg.avif'), cover);
      @if $isSsm {
        border-width: 0.25rem;
        border-color: #feb942;
      }
      @if $isWsm {
        border-width: 0.25rem;
        border-color: #fadd1e;
      }
    }

    :deep() {
      .enter-button,
      .join-button {
        width: 24.625rem;
      }
    }
  }
}

.divider {
  height: 4.375rem;

  @if $isWsm {
    background-color: #249b19;
    border: solid 0.125rem #3fe443;
  }

  @if $isSsm {
    background-color: #09b59f;
    border: solid 0.125rem #0ee7cc;
  }
}
</style>
